import { useTheme } from '@emotion/react';
import {
	createContext,
	useMemo,
	useContext,
	useState,
	PropsWithChildren,
	useEffect,
} from 'react';

import { BookingMapContextValues } from '../../../context/types';
import { MapPopUp } from '../components/MapPopUp';

const BookingMapContext = createContext({} as BookingMapContextValues);

export function BookingMapProvider({ children }: PropsWithChildren) {
	const [isMapOpen, setIsMapOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const { mode } = useTheme();

	useEffect(() => {
		setIsLoading(true);
	}, [mode]);

	const value: BookingMapContextValues = useMemo(
		() => ({
			isMapOpen,
			setIsMapOpen,
			isLoading,
			setIsLoading,
		}),
		[isMapOpen, isLoading]
	);

	return (
		<BookingMapContext.Provider value={value}>
			{children}
			{isMapOpen && <MapPopUp />}
		</BookingMapContext.Provider>
	);
}

export const useBookingMapContext = () => useContext(BookingMapContext);
