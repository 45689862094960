import { Button } from '@mms/mms-ui-library';

import { Heading } from '@/components/Heading';
import { Hexagons } from '@/components/Hexagons';
import {
	LOCATION,
	API_BASE_URL,
	MICROSOFT_LOGIN_REDIRECT,
} from '@/constants/index';

import { SignInWrapper, SignInWrapperBtn, Wrapper } from './styles';

export function SignInPage() {
	const login = () => {
		const origin = window.location.origin as LOCATION;
		window.location.href = `${API_BASE_URL[origin]}${MICROSOFT_LOGIN_REDIRECT}?returnUrl=${origin}/auth-callback`;
	};

	return (
		<SignInWrapper>
			<Hexagons />
			<Wrapper>
				<Heading />
				<SignInWrapperBtn>
					<Button onClick={login} fullWidth type="custom">
						Login via Microsoft
					</Button>
				</SignInWrapperBtn>
			</Wrapper>
		</SignInWrapper>
	);
}
