import { useMemo } from 'react';

import { BookedLabel } from '../BookedLabel';
import { usePeriodSelection } from '../hooks/usePeriodSelection';
import { HoursCell } from '../HourCell';
import { useTimelineContext } from '../TimelineContext';

import { TimelineRowSkeleton, TimelineRowWrapper } from './styles';
import { TimelineRowProps } from './types';

export function TimelineRow({
	section,
	bookedData,
	onPeriodSelect,
}: TimelineRowProps) {
	const {
		showLoader,
		selectFromPeriodStart,
		selectionPeriod,
		isPeriodSelectionDisabled,
		cellsAmount,
	} = useTimelineContext();

	const hourCellsArray = useMemo(
		() => Array(cellsAmount).fill(''),
		[cellsAmount]
	);

	const IS_SELECTION_DISABLED = isPeriodSelectionDisabled || showLoader;

	const { handleMouseDown } = usePeriodSelection(
		section,
		selectionPeriod,
		selectFromPeriodStart,
		onPeriodSelect,
		IS_SELECTION_DISABLED
	);

	return (
		<TimelineRowWrapper onMouseDown={handleMouseDown} cellsAmount={cellsAmount}>
			{hourCellsArray.map((_, index) => (
				<HoursCell key={index} isLast={index === hourCellsArray.length - 1} />
			))}
			{showLoader ? (
				<TimelineRowSkeleton animation="wave" cellsAmount={cellsAmount} />
			) : (
				bookedData.map((data) => <BookedLabel key={data.id} {...data} />)
			)}
		</TimelineRowWrapper>
	);
}
