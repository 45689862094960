import { PickersDayProps } from '@mui/x-date-pickers';
import { DayCalendarSlotsComponentsProps } from '@mui/x-date-pickers/internals';
import moment, { Moment } from 'moment';
import {
	ComponentType,
	SyntheticEvent,
	useCallback,
	useMemo,
	useState,
} from 'react';
import { useQueryClient } from 'react-query';

import { BookingQueriesKeys, currentDate } from '@/constants/index';
import { useBookingContext } from '@/context/index';
import {
	CalendarCollisionsDays,
	CalendarHighlightedDays,
} from '@/context/types';
import {
	getEndOfMonthForRequest,
	getStartOfMonthForRequest,
} from '@/helpers/booking';
import { getOfficeBookingRange } from '@/helpers/bookingTime';

import { Day } from './Day';
import { CustomCalendarUI } from './styled';
import { getNewHighlightedDays } from './utils';

export function BookingCalendar() {
	const {
		firstDay,
		setFirstDay,
		lastDay,
		setLastDay,
		highlightedDays,
		meetingRoomCollisions,
		areMeetingRoomsSelected,
		office,
	} = useBookingContext();

	const [endOfMonth, setEndOfMonth] = useState(
		getEndOfMonthForRequest(lastDay)
	);

	const { currentOfficeDateInThreeMonth, currentOfficeDate } = useMemo(
		() => getOfficeBookingRange(office.timeZone),
		[office.timeZone]
	);

	const [startOfMonth, setStartOfMonth] = useState(
		getStartOfMonthForRequest(lastDay)
	);
	const highlightedCollisions: CalendarCollisionsDays = useMemo(() => {
		if (!areMeetingRoomsSelected) {
			return {
				collisionList: [],
			};
		}

		return {
			collisionList: meetingRoomCollisions
				.map(({ reservationFromOfficeDateTime }) =>
					moment(reservationFromOfficeDateTime)
				)
				.filter((collision) => collision.isSame(lastDay, 'M'))
				.map((collision) => collision.get('D')),
		};
	}, [areMeetingRoomsSelected, meetingRoomCollisions]);

	const [hovered, setHovered] = useState(false);
	const [hoveredDateRange, setHoveredDateRange] = useState<(Moment | null)[]>(
		[]
	);

	const newHighlightedDays: Record<keyof CalendarHighlightedDays, number[]> =
		useMemo(
			() =>
				getNewHighlightedDays(
					highlightedDays,
					lastDay,
					startOfMonth,
					endOfMonth
				),
			[highlightedDays, startOfMonth, endOfMonth, lastDay]
		);

	const isSameDate =
		firstDay && lastDay ? firstDay.isSame(lastDay, 'day') : false;

	const queryClient = useQueryClient();

	const handleMonthChange = useCallback((days: Moment) => {
		setEndOfMonth(getEndOfMonthForRequest(days));
		setStartOfMonth(getStartOfMonthForRequest(days));
		queryClient.cancelQueries([BookingQueriesKeys.reservations]);
	}, []);

	const getAvailableDateRange = useCallback(
		(day: Moment | null) => {
			if (day?.isBefore(currentDate, 'day')) {
				return [currentDate, firstDay];
			}

			if (day?.isAfter(currentOfficeDateInThreeMonth, 'day')) {
				return [firstDay, currentOfficeDateInThreeMonth];
			}

			const dateRange = [day, firstDay];

			return day?.isBefore(firstDay) ? dateRange : dateRange.reverse();
		},
		[firstDay]
	);

	const handleMouseEnterDay = useCallback(
		(event: SyntheticEvent, day: unknown) => {
			if (!hovered) {
				return;
			}

			if ((day as Moment).isSame(firstDay)) {
				setHoveredDateRange([]);

				return;
			}

			setHoveredDateRange(getAvailableDateRange(day as Moment));
		},
		[hovered, firstDay]
	);

	const handleMouseLeaveDay = useCallback(() => {
		setHoveredDateRange([]);
	}, []);

	// If you want to change management to date from inputs to calendar, use handleChange instead handleChangeFirstDay, handleChangeLastDay and disable inputs.
	const handleChange = useCallback(
		(newValue: Moment | null) => {
			if (!lastDay && firstDay && newValue?.isBefore(firstDay, 'day')) {
				setFirstDay(newValue);
				setLastDay(firstDay);
				setHovered(false);
				setHoveredDateRange([]);

				return;
			}

			if (!firstDay || lastDay) {
				setFirstDay(newValue);
				setHovered(true);
				setLastDay(null);
			} else {
				setLastDay(newValue);
				setHovered(false);
				setHoveredDateRange([]);
			}
			setEndOfMonth(getEndOfMonthForRequest(newValue));
			setStartOfMonth(getStartOfMonthForRequest(newValue));
		},
		[firstDay, lastDay, setFirstDay, setLastDay]
	);

	return (
		<CustomCalendarUI
			value={lastDay}
			onChange={handleChange}
			onMonthChange={handleMonthChange}
			slots={{ day: Day as ComponentType<PickersDayProps<Moment>> }}
			slotProps={{
				day: {
					selectedDay: currentDate.clone(),
					highlightedDays: {
						...newHighlightedDays,
						...highlightedCollisions,
					},
					firstDay,
					lastDay,
					isSameDate,
					hovered,
					hoveredDateRange,
					lastAvailableDay: currentOfficeDateInThreeMonth,
					onMouseEnter: handleMouseEnterDay,
					onMouseLeave: handleMouseLeaveDay,
				} as DayCalendarSlotsComponentsProps<Moment>,
			}}
			minDate={currentOfficeDate}
			maxDate={currentOfficeDateInThreeMonth}
			disabled
			disablePast
		/>
	);
}
