import {
	PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { destroyLogout } from '@/components/ProtectedRoute';
import { LOGOUT_REASON, LogoutReasons } from '@/constants/authConstants';
import { Routes } from '@/constants/index';
import {
	getAuthState,
	initialAuthState,
	removeAuthState,
	setAuthState,
} from '@/helpers/localStorage';
import { useLogout } from '@/queries/useLogout';
import { UserInfo } from '@/types/UserInfo';

import { AuthState, HandleReceivedToken, InitialAuthState } from './types';

const { VIA_BUTTON } = LogoutReasons;

// Function to notify inactive tabs that it is necessary to logout
export function logoutInOtherTabs(reason: string) {
	// Set the logout reason to localStorage
	localStorage.setItem(LOGOUT_REASON, reason);
}

const AuthContext = createContext<Omit<AuthState, 'loading'>>(initialAuthState);

function AuthProvider({ children }: PropsWithChildren) {
	const navigate = useNavigate();

	const { refetch: logoutRequest } = useLogout();
	const [authorization, setAuthorization] = useState(getAuthState());
	const [userInfo, setUserInfo] = useState<Partial<UserInfo>>({});
	const { token } = authorization;

	const handleReceivedToken = useCallback<HandleReceivedToken>(
		(newToken) => {
			const newState: InitialAuthState = {
				...authorization,
				token: newToken,
			};
			setAuthorization(newState);
			setAuthState(newState);
		},
		[authorization]
	);

	const onLogout = (reason?: LogoutReasons) => {
		logoutRequest();
		removeAuthState();
		setAuthorization(initialAuthState);
		logoutInOtherTabs(reason ?? VIA_BUTTON);
		destroyLogout();
		navigate(Routes.signIn);
	};

	const value = useMemo(
		() => ({
			...authorization,
			...userInfo,
			role: userInfo?.position ?? authorization.role,
			handleReceivedToken,
			onLogout,
			token,
			setUserInfo,
		}),
		[authorization, userInfo, token]
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;

}

/**
 * Hook to use AuthContext.
 * Includes data about current user, authorization events and loading status.
 */
const useAuth = () => useContext(AuthContext) as Required<AuthState>;

export { AuthContext, AuthProvider, useAuth };
