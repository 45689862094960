import { useTheme } from '@emotion/react';
import {
	BookingCalendarIcon,
	DatePicker,
	Typography,
} from '@mms/mms-ui-library';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useBookingContext } from '@/context/index';
import {
	convertMomentToDatePickerDate,
	getOfficeBookingRange,
} from '@/helpers/bookingTime';

import {
	DatePickersContentWrapper,
	DatePickersHeader,
	DatePickersWrapper,
} from './styled';
import { getDatePickerErrorMessage } from './utils';

export function DatePickers() {
	const {
		firstDay,
		setFirstDay,
		lastDay,
		setLastDay,
		setError,
		error,
		timelineDate,
		areMeetingRoomsSelected,
		setTimelineDate,
		office: { timeZone },
	} = useBookingContext();
	const [disabled, setDisabled] = useState(false);
	const [newFromDate, setNewFromDate] = useState<Date | null>(null);
	const [newToDate, setNewToDate] = useState<Date | null>(null);
	const [fromDateIsChanging, setFromDateIsChanging] = useState(false);
	const [toDateIsChanging, setToDateIsChanging] = useState(false);
	const theme = useTheme();

	const setTimelineDateIfNeeded = useCallback(
		(momentValue: Moment) => {
			if (areMeetingRoomsSelected) {
				const momentValueWithTimezone = momentValue.clone().tz(timeZone);

				if (!momentValueWithTimezone.isSame(timelineDate, 'day')) {
					setTimelineDate(momentValueWithTimezone);
				}
			}
		},
		[areMeetingRoomsSelected, timelineDate, timeZone]
	);

	const { currentOfficeDateInThreeMonth, currentOfficeDate: minDate } = useMemo(
		() => getOfficeBookingRange(timeZone),
		[timeZone]
	);

	useEffect(() => {
		setNewFromDate(null);
		setNewToDate(null);
		setFromDateIsChanging(false);
		setToDateIsChanging(false);
	}, [firstDay, lastDay]);

	const handleChangeFirstDay = useCallback(
		(newValue: Date | null) => {
			setFromDateIsChanging(true);
			setNewFromDate(newValue);

			if (!newValue) {
				setFirstDay(null);
				setLastDay(null);
				setDisabled(true);
				setError('invalidDate');

				return;
			}

			const currentOfficeDate = moment().tz(timeZone).startOf('day');

			const momentValue = moment(newValue)
				.clone()
				.tz(timeZone, true)
				.startOf('day');

			if (momentValue?.isBefore(currentOfficeDate.clone(), 'day')) {
				setTimelineDateIfNeeded(momentValue);
				setError('minDate');

				return;
			}

			if (momentValue?.isAfter(currentOfficeDateInThreeMonth, 'day')) {
				setTimelineDateIfNeeded(momentValue);
				setError('maxDate');

				return;
			}

			if (!momentValue?.isValid()) {
				setError('pastDate');
				setDisabled(true);

				return;
			}

			if (momentValue.isBefore(currentOfficeDate, 'day')) {
				setError('pastDate');

				return;
			}

			setDisabled(false);
			setError(null);
			setFirstDay(momentValue);

			setTimelineDateIfNeeded(momentValue);

			if (!momentValue.isSame(firstDay)) {
				setLastDay(momentValue);
			}
			setFromDateIsChanging(false);
		},
		[disabled, firstDay, setTimelineDateIfNeeded, timeZone]
	);

	const handleChangeLastDay = useCallback(
		(newValue: Date | null) => {
			setToDateIsChanging(true);
			setNewToDate(newValue);

			if (!newValue) {
				setLastDay(null);
				setError('invalidDate');

				return;
			}

			const currentOfficeDate = moment().tz(timeZone).startOf('day');

			const momentValue = moment(newValue)
				.clone()
				.tz(timeZone, true)
				.startOf('day');

			if (momentValue?.isBefore(firstDay, 'day')) {
				setError('minDate');

				return;
			}

			if (momentValue?.isAfter(currentOfficeDateInThreeMonth, 'day')) {
				setError('maxDate');

				return;
			}

			if (momentValue?.isBefore(currentOfficeDate, 'day')) {
				setError('pastDate');

				return;
			}

			if (firstDay?.isBefore(currentOfficeDate, 'day')) {
				setError('pastDate');

				return;
			}
			setError(null);
			setLastDay(momentValue);
			setToDateIsChanging(false);
		},
		[firstDay, lastDay, setError, setLastDay]
	);

	useEffect(() => {
		const startOfCurrentDate = moment().tz(timeZone).startOf('day');

		const isDateValid =
			lastDay?.isSameOrAfter(firstDay) &&
			firstDay?.isSameOrAfter(startOfCurrentDate);

		if (isDateValid) {
			setError(null);
			setDisabled(false);
		}
	}, [firstDay, lastDay, timeZone]);

	const datePickerStylesConfig = useMemo(
		() => ({
			label: {
				background: theme.palette['primary-1'],
			},
			errorText: {
				wrapper: {
					background: theme.palette['primary-1'],
				},
			},
		}),
		[theme]
	);

	const errorMessage = useMemo(
		() => getDatePickerErrorMessage(error),

		[error]
	);

	const fromDate = useMemo(
		() => convertMomentToDatePickerDate(firstDay),
		[firstDay]
	);

	const toDate = useMemo(
		() => convertMomentToDatePickerDate(lastDay),
		[lastDay]
	);

	return (
		<DatePickersWrapper>
			<DatePickersHeader>
				<BookingCalendarIcon />
				<Typography variant="s-600">Date</Typography>
			</DatePickersHeader>
			<DatePickersContentWrapper>
				<DatePicker
					innerLabel="From"
					value={fromDateIsChanging ? newFromDate : fromDate}
					onChange={handleChangeFirstDay}
					errorText={errorMessage}
					minDate={convertMomentToDatePickerDate(minDate) || undefined}
					maxDate={
						convertMomentToDatePickerDate(currentOfficeDateInThreeMonth) ||
						undefined
					}
					calendarPosition="bottom-start"
					stylesConfig={datePickerStylesConfig}
				/>
				<DatePicker
					innerLabel="To"
					value={toDateIsChanging ? newToDate : toDate}
					disabled={disabled}
					onChange={handleChangeLastDay}
					calendarPosition="bottom-start"
					minDate={convertMomentToDatePickerDate(firstDay) || undefined}
					maxDate={
						convertMomentToDatePickerDate(currentOfficeDateInThreeMonth) ||
						undefined
					}
					errorText={errorMessage}
					stylesConfig={datePickerStylesConfig}
				/>
			</DatePickersContentWrapper>
		</DatePickersWrapper>
	);
}
