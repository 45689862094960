import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';

import { createMeetingRoomBooking } from '@/constants/index';

import { CreateMeetingRoomBookingVariables } from './types';

export const useCreateMeetingRoomBooking = (
	options?: UseMutationOptions<
		void,
		AxiosError,
		CreateMeetingRoomBookingVariables
	>
) =>
	useMutation<void, AxiosError, CreateMeetingRoomBookingVariables>({
		...options,
		mutationFn: ({ roomId, userId, ...restProps }) =>
			axios
				.post(createMeetingRoomBooking(roomId), {
					userId: userId || undefined,
					...restProps,
				})
				.then((response) => response.data),
	});
