import { lazy, useCallback, useEffect, useMemo } from 'react';
import { Routes as BrowserRoutes, Navigate, Route } from 'react-router-dom';

import { PrivateRoute } from '@/components/PrivateRoute';
import { ProtectedRoute } from '@/components/ProtectedRoute';
import { PATH_KEY } from '@/constants/authConstants';
import {
	chiefsAndSA,
	isEmployee,
	managerRoles,
	Routes,
} from '@/constants/index';
import { useAuth, useToast } from '@/context/index';
import { ListScrollObserverProvider } from '@/context/ListScrollObserver';
import { useBodyBackgroundColor } from '@/hooks/useBodyBackground';
import { Layout } from '@/layouts/main';
import { RedirectPage } from '@/pages/RedirectPage';
import { SignInPage } from '@/pages/SignIn';

const NotFoundPage = lazy(async () => ({
	default: (await import('@/pages/NotFound')).NotFoundPage,
}));
const UsersPage = lazy(async () => ({
	default: (await import('@/pages/Users/index')).UsersPage,
}));
const ProjectsPage = lazy(async () => ({
	default: (await import('@/pages/Projects')).ProjectsPage,
}));
const ClientPage = lazy(async () => ({
	default: (await import('@/pages/Clients/index')).ClientPage,
}));
const TimeSheetPage = lazy(async () => ({
	default: (await import('@/pages/TimeSheet')).TimeSheetPage,
}));
const ReportsPage = lazy(async () => ({
	default: (await import('@/pages/Reports')).ReportsPage,
}));
const DashboardPage = lazy(async () => ({
	default: (await import('@/pages/Dashboard')).DashboardPage,
}));
const ProfilePage = lazy(async () => ({
	default: (await import('@/pages/Profile')).ProfilePage,
}));
const DepartmentsPage = lazy(async () => ({
	default: (await import('@/pages/Departments/index')).DepartmentsPage,
}));
const BookingPage = lazy(async () => ({
	default: (await import('@/pages/Booking')).BookingPage,
}));
const AuthCallbackPage = lazy(async () => ({
	default: (await import('@/pages/AuthCallback')).AuthCallbackPage,
}));
const EquipmentPage = lazy(async () => ({
	default: (await import('@/pages/Equipment')).EquipmentPage,
}));
const AdminPanelPage = lazy(async () => ({
	default: (await import('@/pages/AdminPanel')).AdminPanelPage,
}));

const TeamboardPage = lazy(async () => ({
	default: (await import('@/pages/Teamboard')).TeamboardPage,
}));

export function AppRouter() {
	useBodyBackgroundColor();
	const { role } = useAuth();
	const pathKey = useMemo(() => localStorage.getItem(PATH_KEY), []);
	const toast = useToast();

	useEffect(() => {
		toast.subscribe();
	}, []);

	const navigateTo = useCallback(
		() =>
			(pathKey !== Routes.home && pathKey) ||
			(isEmployee(role) ? Routes.timeSheet : Routes.dashboard),
		[pathKey, role]
	);

	return (
		<BrowserRoutes>
			<Route path={Routes.signIn} element={<SignInPage />} />
			<Route path={Routes.redirectToExternalLink} element={<RedirectPage />} />
			<Route path={Routes.authCallback} element={<AuthCallbackPage />} />
			<Route element={<ProtectedRoute />}>
				<Route path={Routes.setProfile} element={<ProfilePage />} />
				<Route element={<Layout withScroll withAfter={false} withPaddings />}>
					<Route path={Routes.dashboard} element={<DashboardPage />} />
				</Route>
				<Route element={<Layout withPaddings withScroll />}>
					<Route index element={<Navigate to={navigateTo()} />} />
					<Route path={Routes.reports} element={<ReportsPage />} />
					<Route path={Routes.timeSheet} element={<TimeSheetPage />} />

					<Route element={<PrivateRoute available={chiefsAndSA} />}>
						<Route path={Routes.equipment} element={<EquipmentPage />} />
						<Route path={Routes.users} element={<UsersPage />} />
					</Route>
					<Route element={<PrivateRoute available={managerRoles} />}>
						<Route path={Routes.clients} element={<ClientPage />} />
						<Route
							path={Routes.projects}
							element={
								<ListScrollObserverProvider>
									<ProjectsPage />
								</ListScrollObserverProvider>
							}
						/>
						<Route path={Routes.departments} element={<DepartmentsPage />} />
						<Route path={Routes.teamBoard} element={<TeamboardPage />} />
						<Route path={Routes.teamBoardUserId} element={<TeamboardPage />} />
					</Route>
					<Route path={Routes.updateProfile} element={<ProfilePage />} />
				</Route>
				<Route element={<Layout />}>
					<Route
						index
						element={
							<Navigate
								to={isEmployee(role) ? Routes.timeSheet : Routes.dashboard}
							/>
						}
					/>
					<Route path={Routes.booking} element={<BookingPage />} />
					<Route element={<PrivateRoute available={managerRoles} />}>
						<Route path={Routes.adminPanel} element={<AdminPanelPage />} />
					</Route>
				</Route>
			</Route>
			<Route path={Routes.all} element={<NotFoundPage />} />
		</BrowserRoutes>
	);
}
