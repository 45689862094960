import axios from 'axios';
import { useQuery } from 'react-query';

import {
	NotificationType,
	getMeetingRoomsReservationsAPIEndpoint,
	BookingQueriesKeys,
	SOMETHING_WENT_WRONG,
} from '@/constants/index';
import { useToast } from '@/context/index';
import { createToast } from '@/helpers/createToast';

import { GetMeetingRoomsReservationsResponse } from './types';

export const useGetMeetingRoomReservations = (
	dayDate: string | null,
	officeId: number
) => {
	const toast = useToast();

	return useQuery({
		queryKey: [BookingQueriesKeys.meetingRoomReservations, dayDate],
		queryFn: async () => {
			try {
				if (dayDate) {
					const response = axios.get<GetMeetingRoomsReservationsResponse>(
						getMeetingRoomsReservationsAPIEndpoint(officeId, dayDate)
					);

					return (await response).data;
				}

				return undefined;
			} catch {
				toast.open(
					createToast(NotificationType.ERROR, null, SOMETHING_WENT_WRONG)
				);

				return undefined;
			}
		},
		keepPreviousData: false,
		enabled: Boolean(dayDate),
		refetchOnWindowFocus: false,
	});
};
