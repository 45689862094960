import { timeUnits } from '@/constants/date';

export const convertCellIndexToTime = (cellIndex: number, baseHour: number) => {
	const hours = Math.floor(cellIndex) + baseHour;
	const minutes = (cellIndex % 1) * timeUnits.SECONDS_IN_MINUTE;
	const now = new Date();
	const date = new Date(
		now.getFullYear(),
		now.getMonth(),
		now.getDate(),
		hours,
		minutes
	);

	return date;
};
