import { Typography } from '@mms/mms-ui-library';

import { PopUpBody } from '../PopUpBody';

import { useAuth } from '@/context/AuthContext';
import { createPeriodInText, getDeleteDialogText } from '@/helpers/booking';
import { getDialogTextComponent } from '@/helpers/getDeleteDialogTextComponent';
import { getDeskName, getSpaceAbbreviation } from '@/pages/Booking/utils';
import { DeskBooking } from '@/types/Booking';

interface DeleteBookingPopupProps {
	bookingItem: DeskBooking;
}

export function DeleteDeskBookingPopupBody({
	bookingItem,
}: DeleteBookingPopupProps) {
	const {
		isBlock,
		workplaceNumber,
		weeksInInterval,
		dateList,
		workplaceOfficeId,
		workspaceId,
	} = bookingItem;
	const { id } = useAuth();

	const dialogComponent = getDialogTextComponent(bookingItem, id);

	return (
		<PopUpBody>
			<Typography variant="m-400" align="center">
				{getDeleteDialogText(bookingItem, id)}
			</Typography>
			{dialogComponent && (
				<Typography variant="m-400" align="center">
					{dialogComponent}
				</Typography>
			)}
			{!isBlock && (
				<>
					<Typography variant="m-600" align="center">
						{getDeskName(
							getSpaceAbbreviation(workplaceOfficeId, workspaceId),
							workplaceNumber
						)}
					</Typography>
					<Typography variant="m-600" align="center">
						{createPeriodInText(weeksInInterval, dateList)}
					</Typography>
				</>
			)}
		</PopUpBody>
	);
}
