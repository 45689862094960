import { useTheme } from '@emotion/react';
import { Tooltip } from '@mms/mms-ui-library';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Divider } from '@/components/Divider';
import { LogoutReasons, PATH_KEY } from '@/constants/authConstants';
import { SERVICE_DESK_LINK } from '@/constants/links';
import { Routes } from '@/constants/routes';
import { useAuth, useColorMode } from '@/context/index';
import { getFullName } from '@/helpers/utils';
import { ThemeMode } from '@/theme';

import { MenuItem } from './components/MenuItem';
import { menuItemsProps } from './constants';
import {
	AvatarWrapper,
	ContentWrapper,
	CustomAvatar,
	CustomLink,
	DropdownFooter,
	DropdownHeader,
	DropdownWrapper,
	Email,
	HeaderWrapper,
	Position,
	Surname,
	TimeZoneLocaleWrapper,
	TimeZoneTypography,
	TimeZoneWrapper,
} from './styles';
import type { DropdownProps } from './types';
import { localTime, timezoneName } from './utils';

function UserMenuDropdownInner({ onClose }: DropdownProps) {
	const {
		lastName,
		firstName,
		avatarColor,
		rightPosition,
		email,
		avatarFileId,
		onLogout,
	} = useAuth();

	const { toggleThemeMode } = useColorMode();
	const navigate = useNavigate();
	const theme = useTheme();

	const name = getFullName(firstName, lastName);

	const handleProfileEdit = () => {
		onClose();
		localStorage.setItem(PATH_KEY, Routes.updateProfile);
		navigate(Routes.updateProfile);
	};

	return (
		<DropdownWrapper>
			<HeaderWrapper>
				<DropdownHeader />
				<AvatarWrapper>
					<CustomAvatar
						firstName={firstName}
						lastName={lastName}
						fileId={avatarFileId}
						bgColor={avatarColor}
					/>
				</AvatarWrapper>
			</HeaderWrapper>
			<ContentWrapper>
				<Tooltip text={name} fallbackPlacements={['bottom-end', 'left']}>
					<Surname align="center" Component="h5" variant="medium">
						{name}
					</Surname>
				</Tooltip>
				<Position align="center" Component="h6" variant="m-500">
					{rightPosition}
				</Position>
				<Tooltip text={email} position="bottom-end">
					<Email Component="p" variant="m-300">
						{email}
					</Email>
				</Tooltip>
				<TimeZoneTypography align="center" Component="h6" variant="xs-400">
					<TimeZoneWrapper>
						Time zone:{' '}
						<TimeZoneLocaleWrapper>{localTime}</TimeZoneLocaleWrapper>
					</TimeZoneWrapper>
					<span>{timezoneName}</span>
				</TimeZoneTypography>
				<DropdownFooter>
					<MenuItem {...menuItemsProps.profile} onClick={handleProfileEdit} />
					<Divider color={theme.palette.userMenuHeader.borderItems} />
					{theme.palette.mode === ThemeMode.DARK ? (
						<MenuItem
							{...menuItemsProps.lightTheme}
							onClick={toggleThemeMode}
						/>
					) : (
						<MenuItem {...menuItemsProps.darkTheme} onClick={toggleThemeMode} />
					)}
					<Divider color={theme.palette.userMenuHeader.borderItems} />
					<CustomLink
						href={SERVICE_DESK_LINK}
						color="inherit"
						rel="noopener noreferrer"
						target="_blank"
					>
						<MenuItem {...menuItemsProps.serviceDesk} />
					</CustomLink>
					<Divider color={theme.palette.userMenuHeader.borderItems} />
					<MenuItem
						{...menuItemsProps.logOut}
						onClick={() => onLogout(LogoutReasons.VIA_BUTTON)}
					/>
				</DropdownFooter>
			</ContentWrapper>
		</DropdownWrapper>
	);
}

export const UserMenuDropdown = memo(UserMenuDropdownInner);
