import { useTheme } from '@emotion/react';
import { Typography } from '@mms/mms-ui-library';
import { memo } from 'react';

import {
	TimelineSectionsColumnTitleWrapper,
	TimelineSectionsColumnWrapper,
} from './styles';
import { TimelineSection } from './TimelineSection';
import { TimelineSectionsColumnProps } from './types';

function TimelineSectionsColumnInner({
	sections,
	selectedSectionId,
	onSectionSelection,
}: TimelineSectionsColumnProps) {
	const theme = useTheme();

	return (
		<TimelineSectionsColumnWrapper>
			<TimelineSectionsColumnTitleWrapper>
				<Typography color={theme.palette['base-6']} variant="s-400">
					Rooms
				</Typography>
			</TimelineSectionsColumnTitleWrapper>
			<div>
				{sections.map(({ meetingRoomId, meetingRoomName }) => (
					<TimelineSection
						key={meetingRoomId}
						meetingRoomId={meetingRoomId}
						meetingRoomName={meetingRoomName}
						isSelected={selectedSectionId === meetingRoomId}
						onSectionSelection={onSectionSelection}
					/>
				))}
			</div>
		</TimelineSectionsColumnWrapper>
	);
}

export const TimelineSectionsColumn = memo(TimelineSectionsColumnInner);
