import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import moment from 'moment';
import { FunctionComponent, memo } from 'react';

import { COLLISION_BADGE_CLASS_NAME } from './constants';
import { BadgeWithBorder, CustomPickersDay } from './styled';
import type { DayInnerProps, OtherPropsType } from './types';

function DayInner({
	day,
	selectedDay,
	highlightedDays,
	outsideCurrentMonth,
	firstDay,
	lastDay,
	isSameDate,
	hovered,
	hoveredDateRange,
	lastAvailableDay,
	onMouseEnter,
	onMouseLeave,
	...other
}: DayInnerProps) {
	const isSelected =
		!outsideCurrentMonth && highlightedDays.yourList.indexOf(day.date()) >= 0;
	const isBlocked =
		!outsideCurrentMonth &&
		highlightedDays.blockedList.indexOf(day.date()) >= 0;

	const hasCollision =
		!outsideCurrentMonth &&
		highlightedDays.collisionList.indexOf(day.date()) >= 0;

	if (selectedDay === null) {
		return <PickersDay day={day} {...(other as OtherPropsType)} />;
	}

	const dayIsBetween = moment(day).isBetween(
		firstDay,
		lastDay,
		undefined,
		'[]'
	);
	const daysIsHoveredBetween =
		hoveredDateRange.length &&
		moment(day).isBetween(
			hoveredDateRange[0],
			hoveredDateRange[1],
			'day',
			'[]'
		);

	const isFirstDay = moment(day).isSame(firstDay, 'day');
	const isFirstHoveredDay =
		hoveredDateRange[0] && moment(day).isSame(hoveredDateRange[0], 'day');
	const isLastDay = moment(day).isSame(lastDay, 'day');
	const isLastHoveredDay =
		hoveredDateRange[1] && moment(day).isSame(hoveredDateRange[1], 'day');
	const isDisabled = moment(day).isSameOrAfter(lastAvailableDay);
	const isDayEqualsLastDay = moment(day).isSame(lastDay, 'day');
	const isDayEqualsFirstDay = moment(day).isSame(firstDay, 'day');

	const isRange =
		isDayEqualsFirstDay !== isDayEqualsLastDay &&
		(isDayEqualsFirstDay || isDayEqualsLastDay);

	return (
		<BadgeWithBorder
			key={day.toString()}
			overlap="circular"
			variant="dot"
			invisible={!isBlocked && !isSelected && !hasCollision}
			onMouseEnter={onMouseEnter(day)}
			onMouseLeave={onMouseLeave}
			className={hasCollision ? COLLISION_BADGE_CLASS_NAME : undefined}
		>
			<CustomPickersDay
				{...(other as OtherPropsType)}
				day={day}
				id={isRange ? 'rangeDays' : ''}
				dayIsBetween={dayIsBetween}
				isFirstDay={isFirstDay}
				isFirstHoveredDay={isFirstHoveredDay}
				isLastHoveredDay={isLastHoveredDay}
				isLastDay={isLastDay}
				isDayEqualsLastDay={isDayEqualsLastDay}
				isDayEqualsFirstDay={isDayEqualsFirstDay}
				completeRange={firstDay && lastDay}
				className={isDisabled ? `Mui-disabled` : ''}
				hovered={hovered}
				isSameDate={isSameDate}
				outsideCurrentMonth={outsideCurrentMonth}
				daysIsHoveredBetween={daysIsHoveredBetween}
			/>
		</BadgeWithBorder>
	);
}

export const Day = memo(DayInner) as FunctionComponent<DayInnerProps>;
