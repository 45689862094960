import { useCallback } from 'react';
import { useIsFetching, useQueryClient } from 'react-query';

import {
	ALL_DAY_TIME,
	ALREADY_BOOKED,
	BookingQueriesKeys,
	CANT_BOOK_ROOM_FOR_PAST,
	DESK_BOOKING_CREATED,
	DESK_IS_ALREADY_BOOKED,
	HttpStatuses,
	isEmployee,
	MEETING_ROOM_BOOKING_CREATED,
	NotificationType,
	RequestTypes,
	SOMETHING_WENT_WRONG,
} from '@/constants/index';
import { useBookingContext } from '@/context/Booking';
import { useAuth, useToast } from '@/context/index';
import { SelectedBookingItemType } from '@/context/types';
import { createToast } from '@/helpers/createToast';
import {
	getRequestBookingDate,
	getRequestBookingDateTime,
} from '@/pages/Booking/utils';
import { useCreateBooking } from '@/queries/booking';
import { useCreateMeetingRoomBooking } from '@/queries/booking/useCreateMeetingRoomBooking';

export const useMutateBookingItem = (
	isChecked: boolean,
	setIsChecked: React.Dispatch<React.SetStateAction<boolean>>,
	setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
	setWarning: React.Dispatch<React.SetStateAction<string | null>>
) => {
	const { id, role } = useAuth();

	const toast = useToast();

	const isWorkplaceCollisionFetching = useIsFetching({
		queryKey: [BookingQueriesKeys.collisions],
	});

	const isMeetingRoomCollisionFetching = useIsFetching({
		queryKey: [BookingQueriesKeys.meetingRoomCollisions],
	});

	const {
		selectedItem,
		selectedTime,
		setSelectedItem,
		firstDay,
		lastDay,
		employee,
		office,
		init,
		timelineDate,
		weeksCount,
		isAllDayChecked,
		isSelectedMeetingRoomTimeInPast,
	} = useBookingContext();

	const isBookButtonDisabled =
		!selectedItem ||
		isWorkplaceCollisionFetching ||
		isMeetingRoomCollisionFetching;

	const weeksInterval = isChecked ? weeksCount : undefined;

	const timezone = office.timeZone;

	const queryClient = useQueryClient();

	const { mutate } = useCreateBooking({
		onSuccess: () => {
			queryClient.invalidateQueries(BookingQueriesKeys.bookings);
			queryClient.refetchQueries(BookingQueriesKeys.reservations);
			setSelectedItem(null);
			toast.open(
				createToast(NotificationType.SUCCESS, null, DESK_BOOKING_CREATED)
			);
		},
		onError: (error) => {
			if (error.response?.status === HttpStatuses.ConflictRequest) {
				if (error.response?.data.Message === ALREADY_BOOKED) {
					setWarning(ALREADY_BOOKED);
				} else {
					setWarning(DESK_IS_ALREADY_BOOKED);
					queryClient.invalidateQueries(BookingQueriesKeys.bookings);
					queryClient.refetchQueries(BookingQueriesKeys.reservations);
					init();
				}
			} else {
				toast.open(
					createToast(NotificationType.ERROR, null, SOMETHING_WENT_WRONG)
				);
			}
		},
	});

	const { mutate: mutateMeetingRooms } = useCreateMeetingRoomBooking({
		onSuccess: () => {
			queryClient.invalidateQueries(BookingQueriesKeys.meetingRoomsBookings);
			queryClient.refetchQueries([BookingQueriesKeys.meetingRoomReservations]);
			setSelectedItem(null);
			toast.open(
				createToast(
					NotificationType.SUCCESS,
					null,
					MEETING_ROOM_BOOKING_CREATED
				)
			);
		},
		onError: () => {
			setWarning(DESK_IS_ALREADY_BOOKED);

			queryClient.invalidateQueries(BookingQueriesKeys.meetingRoomsBookings);
			queryClient.refetchQueries([BookingQueriesKeys.meetingRoomReservations]);
		},
	});

	const handleBookClick = useCallback(() => {
		if (isBookButtonDisabled) {
			return;
		}

		if (
			selectedTime &&
			selectedItem.type === SelectedBookingItemType.Room &&
			isSelectedMeetingRoomTimeInPast
		) {
			toast.open(
				createToast(NotificationType.ERROR, null, CANT_BOOK_ROOM_FOR_PAST)
			);

			return;
		}

		if (
			selectedItem.type === SelectedBookingItemType.Room &&
			firstDay &&
			lastDay &&
			selectedTime
		) {
			mutateMeetingRooms({
				roomId: selectedItem.id,
				reservationFromUtc: getRequestBookingDateTime(
					firstDay,
					selectedTime.from,
					isAllDayChecked,
					ALL_DAY_TIME.from,
					timezone
				),
				reservationToUtc: getRequestBookingDateTime(
					firstDay,
					selectedTime.to,
					isAllDayChecked,
					ALL_DAY_TIME.to,
					timezone
				),
				reservationPeriodFromOfficeDate: getRequestBookingDate(firstDay),
				reservationPeriodToOfficeDate: getRequestBookingDate(lastDay),
				repeatReservationWeeks: weeksInterval || 0,
				userId: employee ? employee.id : id,
			});
		} else {
			mutate({
				workplaceId: selectedItem.id,
				weeksInterval,
				fromDate: firstDay,
				toDate: lastDay,
				requestType: RequestTypes.CreateSelf,
				userId:
					id === employee?.id || isEmployee(role) ? undefined : employee?.id,
				officeId: office.id,
			});
		}

		setIsChecked(false);
		setSelectedItem(null);
	}, [
		selectedTime,
		isBookButtonDisabled,
		selectedItem,
		timelineDate,
		firstDay,
		lastDay,
		weeksInterval,
		isAllDayChecked,
		office,
		employee?.id,
		id,
	]);

	const handleBlock = useCallback(() => {
		if (!selectedItem) {
			return;
		}
		mutate({
			workplaceId: selectedItem.id,
			fromDate: firstDay,
			toDate: lastDay,
			requestType: RequestTypes.CreateBlock,
			userId: employee?.id,
			officeId: office.id,
		});

		setIsDialogOpen(false);
	}, [selectedItem, firstDay, lastDay, employee?.id, id]);

	return {
		handleBookClick,
		handleBlock,
	};
};
