import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import {
	getMeetingRoomsBookingsAPIEndpoint,
	BookingQueriesKeys,
} from '@/constants/index';

import { GetRoomsBookingResponse } from './types';

export const useGetRoomsBookings = (
	officeId: number,
	options?: UseQueryOptions<GetRoomsBookingResponse, AxiosError>
) =>
	useQuery<GetRoomsBookingResponse, AxiosError>({
		queryKey: [BookingQueriesKeys.meetingRoomsBookings, officeId],
		queryFn: async () => {
			try {
				const { data } = await axios.get<GetRoomsBookingResponse>(
					getMeetingRoomsBookingsAPIEndpoint(officeId)
				);

				return data;
			} catch (error) {
				throw error as AxiosError;
			}
		},
		keepPreviousData: true,
		enabled: Boolean(officeId),
		refetchOnWindowFocus: false,
		...options,
	});
