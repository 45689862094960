import { useRef } from 'react';

import { CurrentTimeIndicator } from '../CurrentTimeIndicator';
import { useScrollTimelineToCurrentTime } from '../hooks/useScrollTimelineToCurrentTime';
import { SelectionBox } from '../SelectionBox';
import { useTimelineContext } from '../TimelineContext';
import { TimelineRow } from '../TimelineRow';

import { CurrentDate } from './CurrentDate';
import { TimelineGridCellsWrapper, TimelineGridWrapper } from './styles';
import { TimeLabels } from './TimeLabels';
import { TimelineGridProps } from './types';

export function TimelineGrid({
	data,
	selectedDate,
	selectedPeriod,
	onPeriodSelect,
	selectedSectionId,
}: TimelineGridProps) {
	const {
		isPeriodSelectionDisabled,
		hasCurrentTimeIndicator,
		showLoader,
		cellsAmount,
		scrollToCurrentTime,
	} = useTimelineContext();

	const timelineGridRef = useRef<HTMLDivElement>(null);

	const IS_SELECTION_IN_RANGE =
		selectedPeriod && selectedPeriod.from < cellsAmount;

	const IS_SELECTION_SHOWN =
		!showLoader &&
		!isPeriodSelectionDisabled &&
		IS_SELECTION_IN_RANGE &&
		selectedSectionId;

	useScrollTimelineToCurrentTime(
		timelineGridRef,
		selectedPeriod,
		scrollToCurrentTime
	);

	const positionIndex =
		data.findIndex(({ meetingRoomId }) => meetingRoomId === selectedSectionId) +
		1;

	return (
		<TimelineGridWrapper ref={timelineGridRef} cellsAmount={cellsAmount}>
			{hasCurrentTimeIndicator && <CurrentTimeIndicator />}
			<CurrentDate selectedDate={selectedDate} />
			<TimeLabels />
			<TimelineGridCellsWrapper>
				{IS_SELECTION_SHOWN && (
					<SelectionBox
						selectedSectionPositionIndex={positionIndex}
						selectedPeriod={selectedPeriod}
					/>
				)}
				{data.map(({ reservations, ...restProps }) => (
					<TimelineRow
						section={restProps}
						key={restProps.meetingRoomId}
						bookedData={reservations}
						onPeriodSelect={onPeriodSelect}
					/>
				))}
			</TimelineGridCellsWrapper>
		</TimelineGridWrapper>
	);
}
