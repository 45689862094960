import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { lightTheme, Typography } from '@mms/mms-ui-library';
import { alpha } from '@mui/material';

import { DeskDirectionRotateDegree, Position } from '../../../../types';

import { Avatar } from '@/components/Avatar';
import { BorderRadiuses } from '@/constants/borderRadiuses';
import { PLAIN_TEXT_FONT_SIZE } from '@/constants/commonStyles';
import { BOOKING_TABS_CHANGE_SCREEN_WIDTH } from '@/pages/Booking/constants';
import { DeskSize } from '@/types/Booking';

import { ColorType, DeskTooltipTextWrapperProps } from './types';

const AVATAR_TYPOGRAPHY = {
	fontSize: '16px',
	lineHeight: '20px',
};

const TABLET_AVATAR_TYPOGRAPHY = {
	fontSize: '12px',
	lineHeight: '16px',
};
const TITLE_TYPOGRAPHY = {
	lineHeight: '12px',
};

const ALPHA_VALUE = 0.5;
const BACKGROUND_OPACITY = 'b3';
const DESK_WRAPPER_TRANSITION = 'all .2s ease-in-out';

const getTypographyColor = () => lightTheme.palette['primary-1'];

const cssHelper = (func: (param: ColorType) => object, param: ColorType) => ({
	...func(param),
	'&:hover': {
		cursor: 'unset',
	},
	'&:active': {},
});

const getPlaceColor = (
	isBlocked: boolean,
	isBooked: boolean,
	isBookedByUser: boolean,
	theme: Theme
) => {
	if (!isBlocked && !isBooked && !isBookedByUser) {
		return theme.palette['accent-6'];
	}

	return getTypographyColor();
};

const isHorizontalDesk = (rotationDegree: number) =>
	rotationDegree === 0 || rotationDegree === 180;

const largeDeskHeight = 80;
const largeDeskWidth = 60;
const smallDeskHeight = 70;
const smallDeskWidth = 46;
const DESK_BORDER_WIDTH = 2;

const DESK_SEAT_WIDTH = 20;
const DESK_SEAT_HEIGHT = 6;

const getSizes = (size: DeskSize, rotationDegree: number) => {
	if (size === DeskSize.Small) {
		if (isHorizontalDesk(rotationDegree)) {
			return {
				height: smallDeskHeight,
				width: smallDeskWidth,
			};
		}

		return {
			height: smallDeskWidth,
			width: smallDeskHeight,
		};
	}

	if (isHorizontalDesk(rotationDegree)) {
		return {
			height: largeDeskHeight,
			width: largeDeskWidth,
		};
	}

	return {
		height: largeDeskWidth,
		width: largeDeskHeight,
	};
};

export const DeskTitle = styled(Typography)<{ rotationDegree: number }>(
	({ theme }) => ({
		userSelect: 'none',
		...TITLE_TYPOGRAPHY,
		position: 'absolute',
		color: theme.palette['base-4'],
	})
);

export const BookedAvatar = styled(Avatar)(({ theme }) => ({
	justifyContent: 'flex-start',
	width: 'fit-content',
	height: 'fit-content',
	...AVATAR_TYPOGRAPHY,
	backgroundColor: 'transparent',
	borderRadius: BorderRadiuses.none,
	color: getTypographyColor(),

	[theme.breakpoints.down(BOOKING_TABS_CHANGE_SCREEN_WIDTH)]: {
		...TABLET_AVATAR_TYPOGRAPHY,
	},
}));

export const DeskTooltipTextWrapper = styled(
	'div'
)<DeskTooltipTextWrapperProps>(({ isMobile }) => ({
	display: 'inline-block',
	whiteSpace: 'pre-wrap',
	maxWidth: isMobile ? '15ch' : '43ch',
	wordWrap: 'break-word',
	fontSize: PLAIN_TEXT_FONT_SIZE,
}));

const getFlexDirection = (rotationDegree: number) => {
	switch (rotationDegree) {
		case DeskDirectionRotateDegree.Bottom:
			return 'column';
		case DeskDirectionRotateDegree.Left:
			return 'row-reverse';
		case DeskDirectionRotateDegree.Top:
			return 'column-reverse';
		default:
			return 'row';
	}
};

export const WorkplaceContent = styled('div')<
	Position & {
		rotationDegree: number;
		size: DeskSize;
	}
>(
	({ rotationDegree, left, top }) =>
		({
			position: 'absolute',
			left,
			top,
			display: 'flex',
			alignItems: 'center',
			flexDirection: getFlexDirection(rotationDegree),
		} as const)
);

const getCommonColorStyles = () => (color: string) => ({
	backgroundColor: `${color}${BACKGROUND_OPACITY}`,
	h6: {
		color: getTypographyColor(),
	},
});

interface WorkplaceProps {
	isBlocked: boolean;
	isBooked: boolean;
	isBookedByUser: boolean;
	isSelected: boolean;
}

const getColorStyles = (
	isBlocked: boolean,
	isBooked: boolean,
	isBookedByUser: boolean,
	selected: boolean,
	theme: Theme
) => ({
	...(isBlocked && {
		...cssHelper(getCommonColorStyles(), theme.palette['feedback-info']),
	}),
	...(isBooked && {
		...cssHelper(getCommonColorStyles(), theme.palette['feedback-success']),
	}),
	...(isBookedByUser && {
		...cssHelper(getCommonColorStyles(), theme.palette['accent-6']),
	}),
	...(selected && {
		borderColor: theme.palette['accent-6'],
		'&:hover': {
			cursor: 'pointer',
			borderColor: theme.palette['accent-6'],
			backgroundColor: alpha(
				theme.palette.bookingWorkplace.selected,
				ALPHA_VALUE
			),
		},

		h6: {
			color: getPlaceColor(Boolean(isBlocked), isBooked, isBookedByUser, theme),
		},
	}),
});

export const DeskWrapper = styled('div')<
	WorkplaceProps & { rotationDegree: number; size: DeskSize }
>(
	({
		theme,
		isBlocked,
		isBooked,
		isBookedByUser,
		isSelected,
		rotationDegree,
		size,
	}) => ({
		border: `${DESK_BORDER_WIDTH}px solid ${theme.palette['primary-1']}`,
		padding: theme.spaces.s,
		borderRadius: BorderRadiuses.xl,
		cursor: 'pointer',
		backgroundColor: theme.palette['base-2'],
		transition: DESK_WRAPPER_TRANSITION,
		...getColorStyles(isBlocked, isBooked, isBookedByUser, isSelected, theme),
		...getSizes(size, rotationDegree),

		[`&:hover`]: {
			backgroundColor: theme.palette['primary-4'],
		},
	})
);

const deskSeatBorderRadiusStyles = (rotationDegree: number) => {
	if (rotationDegree === DeskDirectionRotateDegree.Top) {
		return {
			borderBottomLeftRadius: BorderRadiuses.s,
			borderBottomRightRadius: BorderRadiuses.s,
		};
	}

	if (rotationDegree === DeskDirectionRotateDegree.Bottom) {
		return {
			borderTopLeftRadius: BorderRadiuses.s,
			borderTopRightRadius: BorderRadiuses.s,
		};
	}

	if (rotationDegree === DeskDirectionRotateDegree.Right) {
		return {
			borderTopLeftRadius: BorderRadiuses.s,
			borderBottomLeftRadius: BorderRadiuses.s,
		};
	}

	return {
		borderTopRightRadius: BorderRadiuses.s,
		borderBottomRightRadius: BorderRadiuses.s,
	};
};

export const DeskSeatWrapper = styled('div')<{ rotationDegree: number }>(
	({ theme, rotationDegree }) => ({
		height: isHorizontalDesk(rotationDegree)
			? DESK_SEAT_WIDTH
			: DESK_SEAT_HEIGHT,
		width: isHorizontalDesk(rotationDegree)
			? DESK_SEAT_HEIGHT
			: DESK_SEAT_WIDTH,
		overflow: 'hidden',
		display: 'flex',
		flexShrink: 0,
		backgroundColor: theme.palette['base-2'],
		...deskSeatBorderRadiusStyles(rotationDegree),
	})
);
