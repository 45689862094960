import { useEffect, useState } from 'react';

import { extractHighlightedDaysFromResponse } from '../../utils';

import { TabPanel } from '@/components/TabPanel';
import { Roles } from '@/constants/index';
import { useAuth } from '@/context/AuthContext';
import { useBookingContext } from '@/context/Booking';
import { BookingList, BookingManagement } from '@/pages/Booking/components';
import { useGetBookings } from '@/queries/booking';
import { useGetRoomsBookings } from '@/queries/booking/useGetRoomsBookings';

import { BookingBlockHeader } from './components/BookingBlockHeader';
import { BookingBlockTabs } from './components/BookingBlockTabs';
import {
	BookingsBlockContent,
	BookingsBody,
	BookingBlockWrapper,
} from './styled';

export function BookingBlock() {
	const [activeTabIndex, setActiveTabIndex] = useState(0);

	const { id, role } = useAuth();
	const { setHighlightedDays, office } = useBookingContext();

	const {
		data: desksData,
		isSuccess: isDesksSuccess,
		isLoading: areDesksLoading,
	} = useGetBookings({
		userId: id,
		includeEmployee: role !== Roles.SA,
		officeId: office.id,
	});

	const {
		data: roomsData,
		isSuccess: isRoomsSuccess,
		isLoading: areRoomsLoading,
	} = useGetRoomsBookings(office.id, { initialData: [] });

	useEffect(() => {
		if (roomsData && desksData) {
			setHighlightedDays(
				extractHighlightedDaysFromResponse(desksData, roomsData, id)
			);
		}
	}, [roomsData, desksData]);

	const showLoader =
		!isDesksSuccess || !isRoomsSuccess || areRoomsLoading || areDesksLoading;

	return (
		<BookingBlockWrapper>
			<BookingBlockHeader />
			<BookingsBlockContent>
				<BookingsBody>
					<BookingBlockTabs
						activeTab={activeTabIndex}
						onChange={setActiveTabIndex}
					/>
					<TabPanel value={activeTabIndex} index={0}>
						<BookingManagement />
					</TabPanel>
					<TabPanel value={activeTabIndex} index={1}>
						<BookingList
							showLoader={showLoader}
							deskData={desksData}
							roomsData={roomsData}
						/>
					</TabPanel>
				</BookingsBody>
			</BookingsBlockContent>
		</BookingBlockWrapper>
	);
}
