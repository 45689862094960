import axios from 'axios';
import { useQuery } from 'react-query';

import {
	NotificationType,
	GET_RESERVATION_API_ENDPOINT,
	BookingQueriesKeys,
	SOMETHING_WENT_WRONG,
} from '@/constants/index';
import { useToast } from '@/context/index';
import { createToast } from '@/helpers/createToast';

import { GetReservationResponse } from './types';

interface UseGetReservationOptions {
	fromDate: string | undefined | null;
	toDate: string | undefined | null;
	officeId: number;
	error: string | null;
	workspaceId: number | undefined;
	enabled: boolean;
}

export const useGetReservation = ({
	fromDate,
	toDate,
	officeId,
	error,
	workspaceId,
	enabled,
}: UseGetReservationOptions) => {
	const toast = useToast();

	return useQuery({
		queryKey: [
			BookingQueriesKeys.reservations,
			fromDate,
			toDate,
			officeId,
			workspaceId,
			enabled,
		],
		queryFn: async () => {
			try {
				if (officeId && fromDate && toDate && !error) {
					const response = axios.get<GetReservationResponse>(
						GET_RESERVATION_API_ENDPOINT,
						{
							params: {
								fromDate: fromDate.replace(/\+.*/, 'Z'),
								toDate: toDate.replace(/\+.*/, 'Z'),
								officeId,
								workspaceId,
							},
						}
					);

					return (await response).data;
				}

				return undefined;
			} catch {
				toast.open(
					createToast(NotificationType.ERROR, null, SOMETHING_WENT_WRONG)
				);

				return undefined;
			}
		},
		keepPreviousData: true,
		enabled: enabled && Boolean(fromDate) && Boolean(toDate),
		refetchOnWindowFocus: false,
	});
};
