import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import notFound from '@/assets/notFound/notFound.svg';
import { NOT_FOUND_MESSAGE, Routes } from '@/constants/index';

import {
	StyledDescriptionText,
	StyledHeaderText,
	StyledMessageText,
} from './styles';

export function NotFoundPage() {
	return (
		<Box
			sx={{
				display: 'flex',
				width: '90%',
				height: '80vh',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				alignSelf: 'center',
				margin: 'auto',
			}}
		>
			<img src={notFound} alt="not found" loading="lazy" />
			<StyledHeaderText variant="m-400" Component="h1">
				Oops!
			</StyledHeaderText>
			<StyledMessageText variant="m-400" Component="h5">
				{NOT_FOUND_MESSAGE}
			</StyledMessageText>
			<StyledDescriptionText variant="m-500" Component="h6" align="center">
				The page you&apos;re looking for might be removed or temporary
				unavailable <Link to={Routes.dashboard}>Go home.</Link>
			</StyledDescriptionText>
		</Box>
	);
}
