import axios from 'axios';
import { useQuery } from 'react-query';

import {
	getWorkplacesByOfficeIdAPIEndpoint,
	BookingQueriesKeys,
} from '@/constants/index';

import { GetSpaceWorkplacesConfig } from './types';

export const useGetOfficeWorkplaces = (officeId: number) =>
	useQuery({
		queryKey: [BookingQueriesKeys.spaceWorkplaces, officeId],
		queryFn: async () => {
			if (officeId) {
				const response = await axios.get<GetSpaceWorkplacesConfig>(
					getWorkplacesByOfficeIdAPIEndpoint(officeId)
				);

				return response.data;
			}

			return undefined;
		},
		keepPreviousData: true,
		enabled: Boolean(officeId),
		refetchOnWindowFocus: false,
	});
