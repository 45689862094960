import { extraFiltersBy } from './filters';

export enum Roles {
	CEO = 'CEO',
	CTO = 'CTO',
	GM = 'GroupManager',
	HD = 'HeadOfDepartment',
	SE = 'Employee',
	SA = 'SystemAdministrator',
}

export const AllRoles = [
	Roles.CEO,
	Roles.CTO,
	Roles.SE,
	Roles.HD,
	Roles.SA,
	Roles.GM,
];
export const chiefRoles = [Roles.CEO, Roles.CTO];
export const managerRoles = [...chiefRoles, Roles.HD];
export const chiefsAndSA = [...managerRoles, Roles.SA];
export const employees = [Roles.SA, Roles.SE];

export const employeesWithGroupManager = [...employees, Roles.GM];
export const engineerEmployees = [Roles.SE, Roles.GM];
export const groupManagerAndHead = [Roles.GM, Roles.HD];
export const managerRolesWithGroupManager = [...managerRoles, Roles.GM];

export const rolesSetGroupManagerAsManager = {
	managerRoles: managerRolesWithGroupManager,
	employees,
};

export const rolesSetGroupManagerAsEmployee = {
	managerRoles,
	employees: employeesWithGroupManager,
};

export const isNotChiefRole = (role: Roles) => !chiefRoles.includes(role);
export const isChiefRole = (role: Roles) => chiefRoles.includes(role);
export const isManagerRole = (role: Roles) => managerRoles.includes(role);
export const isEmployee = (role: Roles) => employees.includes(role);
export const isHeadOfDepartment = (role: Roles) => role === Roles.HD;
export const isCEO = (role: Roles) => role === Roles.CEO;
export const isCTO = (role: Roles) => role === Roles.CTO;
export const isSysAdmin = (role: Roles) => role === Roles.SA;

export const isHeadOrGroupManager = (role: Roles) =>
	groupManagerAndHead.includes(role);
export const isEmployeeWithGroupManager = (role: Roles) =>
	employeesWithGroupManager.includes(role);
export const isManagerRoleOrGroupManager = (role: Roles) =>
	managerRolesWithGroupManager.includes(role);

export const RoleExtraOptions = {
	...extraFiltersBy,
	AllUsers: 'All Users',
};

export const ExtraRoles = [
	RoleExtraOptions.AllUsers,
	RoleExtraOptions.Personal,
];
