import { Tooltip } from '@mms/mms-ui-library';
import { useCallback, memo } from 'react';

import { getPortalContainer } from '@/components/Grid/utils/getPortalContainer';
import { BookPlaceType } from '@/constants/index';
import { useAuth } from '@/context/AuthContext';
import { getFullName } from '@/helpers/index';
import { WORKPLACE_ELEMENT_ID } from '@/pages/Booking/constants';

import {
	WorkplaceContent,
	DeskWrapper,
	DeskSeatWrapper,
	DeskTitle,
	BookedAvatar,
	DeskTooltipTextWrapper,
} from './styled';
import type { DeskInnerProps } from './types';

const { Blocked, Booked } = BookPlaceType;

function DeskInner({
	id,
	top,
	left,
	firstName,
	lastName,
	userId,
	isBlocked,
	isSelected,
	onClick,
	rotationDegree,
	displayNumber,
	isMobileView,
	size,
}: DeskInnerProps) {
	const { id: personId } = useAuth();
	const userInitials =
		lastName && firstName && getFullName(firstName, lastName);

	const isBooked = !isBlocked && !!userId && userId !== personId;
	const isBookedByUser = userId === personId;

	const handleClick = useCallback(
		() =>
			onClick(
				isSelected
					? null
					: {
							id,
							displayNumber,
					  }
			),
		[onClick, isSelected]
	);

	return (
		<Tooltip
			open={Boolean(userInitials)}
			position="right"
			header={
				<DeskTooltipTextWrapper isMobile={Boolean(isMobileView)}>
					Desk is {isBlocked ? Blocked : Booked} by {userInitials}
				</DeskTooltipTextWrapper>
			}
			getPortalContainer={getPortalContainer(`#${WORKPLACE_ELEMENT_ID}`)}
			fallbackPlacements={[
				'bottom',
				'right',
				'left',
				'top',
				'top-start',
				'top-end',
				'bottom-start',
				'bottom-end',
				'left-start',
				'left-end',
				'right-start',
				'right-end',
			]}
		>
			<WorkplaceContent
				onClick={handleClick}
				size={size}
				left={left}
				top={top}
				rotationDegree={rotationDegree}
			>
				<DeskSeatWrapper rotationDegree={rotationDegree} />
				<DeskWrapper
					{...{ size, isBooked, isBookedByUser, rotationDegree }}
					isBooked={isBooked}
					isBlocked={Boolean(isBlocked)}
					isBookedByUser={isBookedByUser}
					isSelected={Boolean(isSelected)}
					rotationDegree={rotationDegree}
				>
					<DeskTitle
						rotationDegree={rotationDegree}
						Component="h6"
						variant="xs-500"
					>
						<>
							{displayNumber}
							{userInitials && (
								<BookedAvatar
									fileId=""
									firstName={firstName}
									lastName={lastName}
								/>
							)}
						</>
					</DeskTitle>
				</DeskWrapper>
			</WorkplaceContent>
		</Tooltip>
	);
}

export const Desk = memo(DeskInner);
