import { Tabs } from '@mms/mms-ui-library';
import { useMemo } from 'react';

import { MEETING_ROOMS_TAB_ID } from '../../constants';

import { TabsWrapper } from './styled';
import { SpacesTabsProps } from './types';

const MEETING_ROOMS_TAB_NAME = 'Meeting Rooms';

export function SpacesTabs({
	activeTab,
	onChange,
	spaces,
	hasMeetingRooms,
	activeTabsId,
}: SpacesTabsProps) {
	const tabs = useMemo(() => {
		const spacesTabs = spaces.map(({ name, internalCode, id }) => ({
			id,
			text: name,
			disabled: !activeTabsId?.includes(internalCode),
		}));

		if (hasMeetingRooms) {
			spacesTabs.push({
				id: MEETING_ROOMS_TAB_ID,
				text: MEETING_ROOMS_TAB_NAME,
				disabled: !activeTabsId?.includes(MEETING_ROOMS_TAB_ID),
			});
		}

		return spacesTabs;
	}, [spaces]);

	return (
		<TabsWrapper>
			<Tabs
				fullWidth={false}
				type="default"
				needBottomLine={false}
				activeTab={activeTab}
				onChangeTab={onChange}
				data={tabs}
			/>
		</TabsWrapper>
	);
}
