import { Moment } from 'moment';

import { getNearestQuarterHourTime } from '@/helpers/bookingTime';

export const getAvailabilityMessage = (isAvailable: boolean) =>
	isAvailable ? 'Available' : 'Unavailable';

export const getNearestTimeSelectorOption = (
	date?: Moment,
	timeStepOffset = 0
) => {
	const nearestTime = getNearestQuarterHourTime(date, timeStepOffset);

	return {
		id: nearestTime,
		name: nearestTime,
	};
};
