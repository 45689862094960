import { useTheme } from '@emotion/react';

import { useTimelineContext } from '../../TimelineContext';

import {
	MeetingRoomNameTypography,
	TimelineSectionSkeleton,
	TimelineSectionWrapper,
} from './styles';
import { TimelineSectionProps } from './types';

export function TimelineSection({
	meetingRoomId,
	meetingRoomName,
	isSelected,
	onSectionSelection,
}: TimelineSectionProps) {
	const theme = useTheme();

	const { showLoader, isPeriodSelectionDisabled } = useTimelineContext();

	const onTimelineSectionClick = () => {
		if (
			typeof onSectionSelection === 'function' &&
			!showLoader &&
			!isPeriodSelectionDisabled
		) {
			onSectionSelection({ meetingRoomId, meetingRoomName });
		}
	};

	return (
		<TimelineSectionWrapper
			onClick={onTimelineSectionClick}
			isDisabled={showLoader || isPeriodSelectionDisabled}
			isSelected={isSelected && !showLoader && !isPeriodSelectionDisabled}
		>
			{!showLoader ? (
				<MeetingRoomNameTypography
					color={theme.palette['base-6']}
					variant="s-400"
				>
					{meetingRoomName}
				</MeetingRoomNameTypography>
			) : (
				<TimelineSectionSkeleton animation="wave" />
			)}
		</TimelineSectionWrapper>
	);
}
