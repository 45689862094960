import moment from 'moment';
import { memo } from 'react';

import { areMeetingRoomsOpen } from '../../utils';

import { WarningIcon } from '@/assets/icons/Warning';
import { FullSpaceLoader } from '@/components/FullSpaceLoader';
import { useBookingContext } from '@/context/Booking';
import { useFilledDesksWithRangeDate } from '@/hooks/useFilledDesksWithRangeDate';
import { useGetOfficeWorkplaces } from '@/queries/booking/useGetSpaceWorkplaces';

import { MeetingRoomsRenderer } from './components/MeetingRoomsRenderer';
import { SpacesTabs } from './components/SpacesTabs';
import { WorkplaceSpaceRenderer } from './components/WorkplaceSpaceRenderer';
import { MEETING_ROOMS_TAB_ID, OFFICE_CLOSED_MESSAGE } from './constants';
import { useWorkplaceConfiguration } from './hooks/useWorkplaceConfiguration';
import { WorkplaceControls } from './Legend';
import {
	BookingWorkplaceWrapper,
	OfficeWarningContainer,
	OfficeWarningMessageContainer,
	OfficeWarningTypography,
	TitleWrapper,
} from './styled';
import { OfficeWorkspaceType } from './types';

function BookingWorkplaceInner() {
	const {
		firstDay,
		lastDay,
		error,
		office,
		setWorkplaces,
		selectedSpaceId,
		setSelectedSpaceId,
		resetOnMeetingTabChange,
		areMeetingRoomsSelected,
		officeWorkplaces,
	} = useBookingContext();

	const hasSpaces = (office.workspaces || []).length > 0;

	useFilledDesksWithRangeDate(
		moment(firstDay),
		moment(lastDay),
		setWorkplaces,
		error,
		office,
		selectedSpaceId || undefined,
		selectedSpaceId !== MEETING_ROOMS_TAB_ID
	);

	const { configuration, isAvailable, activeTabsId } =
		useWorkplaceConfiguration({
			office,
			spaceId: selectedSpaceId,
			officeWorkplaces,
		});

	const handleSpacesTabChange = (spaceId: number) => {
		if (areMeetingRoomsSelected || areMeetingRoomsOpen(spaceId)) {
			resetOnMeetingTabChange();
		}

		setSelectedSpaceId(spaceId);
	};

	const { isLoading } = useGetOfficeWorkplaces(office.id);

	if (isLoading || configuration === null) {
		return <FullSpaceLoader />;
	}

	return (
		<BookingWorkplaceWrapper>
			{isAvailable ? (
				<>
					{hasSpaces && selectedSpaceId && (
						<TitleWrapper>
							<div>
								<SpacesTabs
									hasMeetingRooms={office.meetingRooms.length > 0}
									activeTab={selectedSpaceId}
									onChange={handleSpacesTabChange}
									spaces={office.workspaces}
									activeTabsId={activeTabsId}
								/>
							</div>
						</TitleWrapper>
					)}
					{configuration?.type === OfficeWorkspaceType.Space && (
						<>
							<WorkplaceSpaceRenderer
								config={configuration}
								spaceId={selectedSpaceId}
							/>
							<WorkplaceControls showLegend />
						</>
					)}
					{configuration?.type === OfficeWorkspaceType.MeetingRooms && (
						<>
							<MeetingRoomsRenderer />
							<WorkplaceControls showLegend={false} />
						</>
					)}
				</>
			) : (
				<OfficeWarningContainer>
					<OfficeWarningMessageContainer>
						<WarningIcon />
						<OfficeWarningTypography variant="m-400">
							{OFFICE_CLOSED_MESSAGE.firstSentence}
							<br />
							{OFFICE_CLOSED_MESSAGE.secondSentence}
						</OfficeWarningTypography>
					</OfficeWarningMessageContainer>
				</OfficeWarningContainer>
			)}
		</BookingWorkplaceWrapper>
	);
}

export const BookingWorkplace = memo(BookingWorkplaceInner);
