import { useTheme } from '@emotion/react';
import {
	BookingCalendarIcon,
	ClockIcon,
	CrossIcon,
	MapPinIcon,
} from '@mms/mms-ui-library';
import { memo, useMemo } from 'react';

import { getFormattedTimePeriod } from '@/helpers/bookingFormatters';
import { getFullName, createPeriodInText } from '@/helpers/index';
import { getMeetingRoomDateList } from '@/pages/Booking/utils';

import {
	ListItemContent,
	BookingListItem,
	IconCloseWrapper,
	BookingListTypography,
	Divider,
	IconWrapper,
	ItemRowWithIcon,
	IconButton,
} from './styled';
import type { RoomBookingListItemProps } from './types';

function ItemInner(props: RoomBookingListItemProps) {
	const {
		weeksInInterval,
		reservationPeriodFromOfficeDate,
		reservationPeriodToOfficeDate,
		handleDelete,
		userFirstName,
		userLastName,
		reservationFromOfficeTime,
		reservationToOfficeTime,
		meetingRoomName,
		isRepetitiveBooking,
	} = props;

	const theme = useTheme();

	const bookingTime = useMemo(
		() =>
			getFormattedTimePeriod(
				reservationFromOfficeTime,
				reservationToOfficeTime,
				true
			),
		[reservationFromOfficeTime, reservationToOfficeTime]
	);

	const bookingDateList = useMemo(
		() =>
			getMeetingRoomDateList(
				reservationPeriodFromOfficeDate,
				reservationPeriodToOfficeDate,
				isRepetitiveBooking
			),
		[
			reservationPeriodFromOfficeDate,
			reservationPeriodToOfficeDate,
			isRepetitiveBooking,
		]
	);

	return (
		<>
			<BookingListItem type="meetingRoom">
				<ListItemContent>
					{userFirstName && userLastName && (
						<BookingListTypography
							Component="p"
							variant="s-600"
							color={theme.palette['base-6']}
						>
							{getFullName(userFirstName, userLastName)}
						</BookingListTypography>
					)}
					<ItemRowWithIcon>
						<IconWrapper>
							<BookingCalendarIcon />
						</IconWrapper>
						{createPeriodInText(weeksInInterval, bookingDateList)
							?.split('\n')
							.map((line, index) => (
								<BookingListTypography
									key={index}
									Component="p"
									variant="s-400"
									color={theme.palette['base-6']}
								>
									{line}
								</BookingListTypography>
							))}
					</ItemRowWithIcon>
					<ItemRowWithIcon>
						<IconWrapper>
							<MapPinIcon />
						</IconWrapper>
						<BookingListTypography
							Component="p"
							variant="s-400"
							color={theme.palette['base-6']}
						>
							{meetingRoomName}
						</BookingListTypography>
					</ItemRowWithIcon>
					<ItemRowWithIcon>
						<IconWrapper>
							<ClockIcon />
						</IconWrapper>
						<BookingListTypography
							Component="p"
							variant="s-400"
							color={theme.palette['base-6']}
						>
							{bookingTime}
						</BookingListTypography>
					</ItemRowWithIcon>
				</ListItemContent>
				<IconCloseWrapper>
					<IconButton onClick={handleDelete}>
						<CrossIcon />
					</IconButton>
				</IconCloseWrapper>
			</BookingListItem>
			<Divider />
		</>
	);
}

const areItemPropsEqual = (
	prevProps: { userId: number },
	nextProps: { userId: number }
) => prevProps.userId === nextProps.userId;

export const RoomBookingListItem = memo(ItemInner, areItemPropsEqual);
