import { Typography } from '@mms/mms-ui-library';
import { styled } from '@mui/material/styles';

export const StyledHeaderText = styled(Typography)(({ theme }) => ({
	marginTop: theme.spacing(1.2),
	marginBottom: theme.spacing(2),
	fontSize: theme.typography.pxToRem(48),
	lineHeight: theme.typography.pxToRem(56),
}));

export const StyledMessageText = styled(Typography)(({ theme }) => ({
	marginBottom: theme.spacing(1),
	fontSize: theme.typography.pxToRem(24),
	lineHeight: theme.typography.pxToRem(32),
}));

export const StyledDescriptionText = styled(Typography)(({ theme }) => ({
	fontSize: theme.typography.pxToRem(20),
	lineHeight: theme.typography.pxToRem(32),
}));
