import { CELL_WIDTH, BOOKED_LABEL_MARGIN } from '../constants';
import { BookedDataTime } from '../types';

import { timeUnits } from '@/constants/date';
import { getFormattedTime } from '@/helpers/bookingFormatters';

export const getXPos = (cellIndex: number) => cellIndex * CELL_WIDTH;

export const getWidth = (duration: number) =>
	duration * CELL_WIDTH - BOOKED_LABEL_MARGIN * 2;

export const formatTimeInterval = (
	fromDateTime: BookedDataTime,
	toDateTime: BookedDataTime
): string => {
	const toDateTimeFormatted = { ...toDateTime };

	if (toDateTime.hours === timeUnits.HOURS_IN_DAY) {
		toDateTimeFormatted.hours = 0;
		toDateTimeFormatted.minutes = 0;
	}

	const formattedTime = `${getFormattedTime(
		fromDateTime.hours,
		fromDateTime.minutes
	)} - ${getFormattedTime(
		toDateTimeFormatted.hours,
		toDateTimeFormatted.minutes
	)}`;

	return formattedTime;
};
