import { Loader } from '@mms/mms-ui-library';

import { LoaderWrapper } from './styles';

export function FullSpaceLoader() {
	return (
		<LoaderWrapper>
			<Loader />
		</LoaderWrapper>
	);
}
