import styled from '@emotion/styled';

export const MapImageContainer = styled('div')(
	() =>
		({
			maxWidth: '100%',
			maxHeight: '100%',
			display: 'flex',
			justifyContent: 'center',

			'& > img': {
				objectFit: 'contain',
				maxWidth: '100%',
				maxHeight: '100%',
			},
		} as const)
);
